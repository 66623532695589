import dbList from "../DBList.json";

const {
    api_server,
    employee_group
} = dbList.server;

const url = api_server + employee_group;

export async function GetEmployeeGroup() {
    const httpRes = await fetch(url, {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: { "accept": "*/*" }
    });

    if (!httpRes.ok) {
        throw new Error(await httpRes.text());
    }

    return await httpRes.json();
}

export async function AddEmployeeGroup(AGroup) {
    const httpRes = await fetch(url, {
        method: "PUT",
        mode: "cors",
        credentials: "include",
        headers: {
            "accept": "*/*",
            "content-type": "application/json"
        },
        body: JSON.stringify(AGroup)
    });

    if (!httpRes.ok) {
        throw new Error(await httpRes.text());
    }
}

export async function UpdateEmployeeGroup(AGroup) {
    const httpRes = await fetch(url, {
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: {
            "accept": "*/*",
            "content-type": "application/json"
        },
        body: JSON.stringify(AGroup)
    });

    if (!httpRes.ok) {
        throw new Error(await httpRes.text());
    }
}

export async function DeleteEmployeeGroup(AId) {
    const httpRes = await fetch(`${ url }/${ AId }`, {
        method: "DELETE",
        mode: "cors",
        credentials: "include",
        headers: { "accept": "*/*" }
    });

    if (!httpRes.ok) {
        throw new Error(await httpRes.text());
    }
}