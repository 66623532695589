import dbList from "../DBList.json";

const {
    api_server,
    clock_data,
    export_clock_data
} = dbList.server;

const url = `${ api_server }${ clock_data }`;
const urlExport = `${ api_server }/${ export_clock_data }`;

export async function GetClockData(AStart, AEnd) {
    const dtStart = new Date(AStart);

    const dtEnd = new Date(AEnd);

    const httpRes = await fetch(`${ url }/gettime`, {
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: { 
            "accept": "*/*",
            "content-type" : "application/json"
        },
        body: JSON.stringify({
            dateStart: dtStart,
            dateEnd: dtEnd
        })
    });

    if (!httpRes.ok) {
        throw new Error(await httpRes.text());
    }

    return await httpRes.json();
}