import dbList from "../DBList.json";

const {
    api_server,
    team
} = dbList.server;

const url = `${api_server}${team}`;

export async function GetTeams() {
    const httpRes = await fetch(url, {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: {
            "accept": "*/*"
        }
    });

    if (!httpRes.ok) {
        throw new Error("Fetching team list has fail.");
    }

    return httpRes.json();
}

export async function UpsertTeam(ATeam) {
    const httpRes = await fetch(url, {
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: {
            "accept": "*/*",
            "content-type": "application/json"
        },
        body: JSON.stringify(ATeam)
    });

    if (!httpRes.ok) {
        throw new Error(`Upsert team failed. ${ await httpRes.text() }`);
    }

    return httpRes.json();
}

export async function DeleteTeam(ATeam) {
    const httpRes = await fetch(`${url}/${ATeam.id}`, {
        method: "DELETE",
        mode: "cors",
        credentials: "include",
        headers: {
            "accept": "*/*"
        }
    });

    if (!httpRes.ok) {
        throw new Error(`Unable to delete selected team. ${ await httpRes.text() }`);
    }

    return httpRes.json();
}