import {
    useState,
    useEffect
} from "react";

import { 
    useDispatch,
    useSelector
} from "react-redux";

import {
    Button,
    TextInput,
    ComboBox,
    CheckBox,
    SaveButton,
    CancelButton
} from "../components/Input";

import { 
    GetUserList,
    UpdateUser,
    AddUser,
    DeleteUser,
    ResetPassword
} from "../api/auth";

import {
    showMsg,
    hideMsg,
    setLoadingNow,
    setLoadingComplete,
    pageSelector
} from "../store/pageSlice";

import { authSelector } from "../store/authSlice";

import Help from "./Help";

import "../App.css";

export default function UserManagement() {
    const dispatch = useDispatch();
    const pageSel = useSelector(pageSelector);
    const authSel = useSelector(authSelector);

    const [lst, setLst] = useState();

    const [search, setSearch] = useState("");

    const [dsUser, setDsUser] = useState([]);

    const [accCodesEnum, setAccCodesEnum] = useState();

    const [selUser, setSelUser] = useState(defaultUser);

    const [isCancel, setIsCancel] = useState(false);
    const [isResetPassword, setIsResetPassword] = useState(false);

    const refreshList = async () => {
        dispatch(setLoadingNow());

        try {
            const res = await GetUserList();
            
            setDsUser(res);

            renderList();

            return res;
        } catch (ex) {
            dispatch(showMsg({
                title: "Error",
                content: `Unable to retrieve user list. ${ ex.message }`
            }));
        } finally {
            dispatch(setLoadingComplete());
        }
    };

    const renderList = () => {
        let dsTemp = dsUser.map((row) => row);

        let usrList = [];

        if (search !== "") {
            dsTemp = dsUser.filter((row) => {
                return row.username.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
                    row.email.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
                    row.firstName.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
                    row.lastName.toLowerCase().indexOf(search.toLowerCase()) >= 0;
            });
        }

        usrList = dsTemp
            .filter((row) => row.username !== authSel.username)
            .map((row) => (
                <div key={ row.id } className="listing-row" onClick={() => { onSelect(row) }}>
                    <strong>{ row.username }</strong>
                    <span>{ row.isActive ? "Active" : "Inactive" }</span>
                </div>
            ));

        setLst(usrList);
    };

    const onRefresh = async () => {
        setSearch("");
        await refreshList();
    };

    const onSelect = (dat) => {
        setSelUser(dat);
    };

    const onAddNew = () => {
        setSelUser(defaultUser);
    };

    const onDelete = () => {
        if (selUser.id === "-1") {
            return;
        }

        dispatch(showMsg({
            title: "Deleting...",
            content: `Are you sure you want to delete user ${ selUser.username }? 
            You can also deactivate user if you want to temporarily disable this user.`,
            id: "DEL_USER",
            isPromptInput: true
        }));
    };

    const onFieldChange = (e, col) => {
        if (col === "USERNAME") {
            setSelUser({
                ...selUser,
                username: e
            });
        } else if (col === "FIRSTNAME") {
            setSelUser({
                ...selUser,
                firstName: e
            });
        } else if (col === "LASTNAME") {
            setSelUser({
                ...selUser,
                lastName: e
            });
        } else if (col === "EMAIL") {
            setSelUser({
                ...selUser,
                email: e
            });
        } else if (col === "ACCESS_CODE") {
            setSelUser({
                ...selUser,
                accessCode: e.target.value
            });
        } else if (col === "IS_ACTIVE") {
            setSelUser({
                ...selUser,
                isActive: e
            });
        }
    };

    const submitChange = async (e) => {
        e.preventDefault();

        if (isCancel || isResetPassword) {
            return;
        }

        dispatch(setLoadingNow());

        try {
            if (selUser.id === "-1") {
                const sCompanyId = window.localStorage.getItem("companyId");
                await AddUser(selUser, sCompanyId);
            } else {
                await UpdateUser(selUser);
            }

            const res = await refreshList();

            const empDat = res.find((row) => row.username === selUser.username);

            onSelect(empDat);

            dispatch(showMsg({
                title: "Success",
                content: "Changes saved successfully!"
            }));
        } catch (ex) {
            dispatch(showMsg({
                title: "Error",
                content: `Saving changes failed. ${ ex.message }`
            }));
        } finally {
            dispatch(setLoadingComplete());
        }
    };

    useEffect(() => {
        const codesEnum = accessCodes.map((row) => (
            <option value={ row.value }>{ row.label }</option>
        ));

        setAccCodesEnum(codesEnum);

        (
            async () => {
                await refreshList();
            }
        )();
    }, []);

    useEffect(() => { renderList() }, [search]);

    useEffect(() => { renderList() }, [dsUser]);

    useEffect(() => {
        if (!pageSel.msgResult) {
            return;
        }

        (
            async () => {
                dispatch(setLoadingNow());

                try {
                    if (pageSel.msgId === "DEL_USER") {
                        await DeleteUser(selUser.id);

                        await refreshList();

                        onAddNew();

                        dispatch(hideMsg());
                    }
                } catch (ex) {
                    dispatch(showMsg({
                        title: "Error",
                        content: `Unable to proceed. ${ ex.message }`
                    }));
                } finally {
                    dispatch(setLoadingComplete());
                }
            }
        )();
    }, [pageSel.msgResult]);

    useEffect(() => {
        if (!isCancel) {
            return;
        }

        const usr = dsUser.filter((row) => row.id === selUser.id);

        if (usr.length === 0) {
            setSelUser(defaultUser);
        } else {
            setSelUser(usr[0]);
        }

        setIsCancel(false);
    }, [isCancel]);

    useEffect(() => {
        if (!isResetPassword) {
            return;
        }

        (
            async () => {
                try {
                    if (selUser.id !== "-1") {
                        await ResetPassword(selUser.username);
        
                        const res = await refreshList();
            
                        const empDat = res.find((row) => row.username === selUser.username);
            
                        onSelect(empDat);
            
                        dispatch(showMsg({
                            title: "Success",
                            content: "Password has been reset! Please set new password within one day."
                        }));
                    }
                } catch (ex) {
                    dispatch(showMsg({
                        title: "Error",
                        content: `Password reset failed. ${ ex.message }`
                    }));
                } finally {
                    setIsResetPassword(false);
                }
            }
        )();
    }, [isResetPassword])

    return (
        <>
            <strong className="title">User Management</strong>
            <div className="panel-horizontal">
                <div className="panel-inner panel-inner-start">
                    <Button src="/img/refresh.svg" onClick={ onRefresh }/>
                    <Button label="New" src="/img/add.svg" onClick={ onAddNew }/>
                    <Button label="Delete" src="/img/remove.svg" onClick={ onDelete }/>
                </div>
                <div className="panel-inner panel-inner-end"></div>
            </div>
            <form className="side-panel" onSubmit={ submitChange }>
                <div className="form-section">
                    <strong>Details</strong>
                </div>
                <div className="form-row">
                    <TextInput 
                        label="Username" 
                        required 
                        readonly={ selUser.id !== "-1" } 
                        value={ selUser.username } 
                        onChange={(e) => { onFieldChange(e, "USERNAME") }}
                    />
                    <TextInput 
                        label="Email" 
                        type="email" 
                        value={ selUser.email }
                        onChange={(e) => { onFieldChange(e, "EMAIL") }}
                    />
                </div>
                <div className="form-row">
                    <TextInput 
                        label="First Name" 
                        value={ selUser.firstName }
                        onChange={(e) => { onFieldChange(e, "FIRSTNAME") }}
                    />
                    <TextInput 
                        label="Last Name" 
                        value={ selUser.lastName }
                        onChange={(e) => { onFieldChange(e, "LASTNAME") }}
                    />
                </div>
                <div className="form-row">
                    <ComboBox 
                        label="Access Code" 
                        opts={ accCodesEnum } 
                        required
                        value={ selUser.accessCode }
                        onChange={(e) => { onFieldChange(e, "ACCESS_CODE") }}
                    />
                    {
                        selUser.id !== "-1" && (
                            <CheckBox label="Active" checked={ selUser.isActive } onChange={() => { onFieldChange(!selUser.isActive, "IS_ACTIVE") }}/>
                        )
                    }
                    {
                        selUser.id === "-1" && (
                            <div></div>
                        )
                    }
                </div>
                {
                    selUser.id !== "-1" && (
                        <div className="form-row">
                            <TextInput label="Date Created" readonly value={ new Date(selUser.dateCreated).toLocaleString() }/>                    
                            {
                                selUser.requestToken !== "" && selUser.requestToken !== null && (
                                    <TextInput label="Recovery URL" value={ `https://iflexiclock.com/?action=recover&token=${ selUser.requestToken }` } readonly/>
                                )
                            }
                            {
                                (selUser.requestToken === "" || selUser.requestToken === null) && (
                                    <div></div>
                                )
                            }
                        </div>
                    )
                }
                <div className="form-row form-row-border-top">
                    <div>
                        {
                            selUser.id !== "-1" && (
                                <Button label="Reset Password" src="/img/lock_reset.svg" onClick={() => { setIsResetPassword(true) }} />
                            )
                        }
                    </div>
                    <div></div>
                    <div className="form-row" style={{ padding: 0 }}>
                        <SaveButton /> 
                        <CancelButton onClick={() => { setIsCancel(true) }}/>
                    </div>
                </div>
            </form>
            <div className="listing">
                <input className="listing-search-box" type="text" placeholder="search" value={ search } onChange={(e) => { setSearch(e.target.value) }}/>
                <div>
                    { lst }
                </div>
            </div>
            <Help sectionName="User Management">
                <h3>What is The Differences Between User Management and Employee Master</h3>
                <ul>
                    <li>User management is maintenance screen used to administrate iFlexi Clock (such as register new employee, export clock data) 
                        and has limited access to iFlexi Clock web app.
                    </li>
                    <li>Employee master is use to manage employee or user of iFlexi Clock mobile app. and has limited access to iFlexi Clock mobile app.</li>
                </ul>
                <h3>Register New User</h3>
                <ul>
                    <li>Click on <strong>"New"</strong> button.</li>
                    <li>Enter particulars.</li>
                    <li>
                        Select access code,
                        <ul>
                            <li><strong>Administrator</strong> - Has access to iFlexi Clock web app. and permitted to control and manage features in iFlexi Clock web app.</li>
                            <li><strong>QR Code</strong> - Has limited access to iFlexi Clock QR code generator in web app.</li>
                        </ul>
                    </li>
                    <li>Click on <strong>"Save"</strong> button to save changes.</li>
                    <li>If email is supplied, request token will be sent to user email address.</li>
                    <li>If email is not supplied during registration, request token can be obtained from user details section.</li>
                </ul>
                <h3>Deactivate User</h3>
                <ul>
                    <li>Deactivating user will block user account from accessing iFlexi Clock web app.</li>
                    <li>To deactivate an user account, click user account in the listing.</li>
                    <li>Uncheck <strong>"Active"</strong> check box.</li>
                    <li>You may re-activate the user account by reversing above steps.</li>
                </ul>
                <h3>Delete User</h3>
                <ul>
                    <li>Deleting user will permanently delete user account. If you are planning for temporary block web app. access, <strong>deactivate user account instead,</strong></li>
                    <li>To delete, select user account from the listing.</li>
                    <li>Click on <strong>"Delete"</strong> delete button.</li>
                    <li><strong>Deleting user account is unreversible.</strong></li>
                </ul>
                <h3>Reset User Password</h3>
                <ul>
                    <li>To reset user password, first select a user.</li>
                    <li>Click on <strong>"Reset Password"</strong> button.</li>
                    <li>System will generate <strong>"Recovery URL"</strong>. Copy the recovery URL and pass to user.</li>
                    <li>The URL only valid for <strong>one day</strong>.</li>
                </ul>
            </Help>
        </>
    );
}

const accessCodes = [
    { label: "Administrator", value: "ADMINISTRATOR" },
    { label: "QR Code", value: "QRCODE" }
];

const defaultUser = {
    id: "-1",
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    accessCode: "ADMINISTRATOR",
    isActive: true,
    dateCreated: "1970-01-01T00:00:00.000Z"
};