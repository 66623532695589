import { createSlice } from "@reduxjs/toolkit";

export const pageSlice = createSlice({
    name: "page",
    initialState: {
        page: "LOGIN",
        msgTitle: "",
        msgContent: "",
        msgId: null,
        msgResult: false,
        msgPromptInput: false,
        showManual: false,
        isLoading: true
    },
    reducers: {
        setPage: (state, action) => {
            return {
                ...state,
                page: action.payload
            };
        },
        showMsg: (state, action) => {
            const {
                title,
                content,
                id,
                isPromptInput
            } = action.payload;

            const msgId = id !== undefined ? id : null;

            return {
                ...state,
                msgTitle: title,
                msgContent: content,
                msgId: msgId,
                msgPromptInput: isPromptInput !== undefined ? isPromptInput : false
            };
        },
        hideMsg: (state) => {
            return {
                ...state,
                msgTitle: "",
                msgContent: "",
                msgId: null,
                msgResult: false,
                msgPromptInput: false
            };
        },
        okMsg: (state) => {
            return {
                ...state,
                msgResult: true
            };
        },
        setShowManual: (state, action) => {
            return {
                ...state,
                showManual: action.payload
            };
        },
        setLoadingNow: (state) => {
            return {
                ...state,
                isLoading: true
            };
        },
        setLoadingComplete: (state) => {
            return {
                ...state,
                isLoading: false
            };
        }
    }
});

export const {
    setPage,
    showMsg,
    hideMsg,
    okMsg,
    setShowManual,
    setLoadingNow,
    setLoadingComplete
} = pageSlice.actions;

export default pageSlice.reducer;

export const pageSelector = (state) => state.page;