import { 
    useState,
    useEffect 
} from "react";

import { 
    useDispatch,
    useSelector 
} from "react-redux";

import {
    TextInput,
    Button,
    CancelButton
} from "../components/Input";

import { 
    showMsg, 
    setLoadingNow,
    setLoadingComplete,
    pageSelector
} from "../store/pageSlice";

import { setAuthorized } from "../store/authSlice";

import * as auth from "../api/auth";

import "../App.css";
import "./Login.css";

export default function Login() {
    const dispatch = useDispatch();
    const pageSel = useSelector(pageSelector);

    const [companyId, setCompanyId] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [showForgot, setShowForgot] = useState(false);
    const [showAccRecovery, setShowAccRecovery] = useState(false);

    const [recEmail, setRecEmail] = useState("");
    const [recToken, setRecToken] = useState("");
    const [recUsername, setRecUsername] = useState("");
    const [recPassword, setRecPassword] = useState("");
    const [recPassword2, setRecPassword2] = useState("");
    const [recCompanyId, setRecCompanyId] = useState("");

    const [reqCompId, setReqCompId] = useState("");
    const [reqUser, setReqUser] = useState("");
    const [reqEmail, setReqEmail] = useState("");

    const onLogin = async (e) => {
        e.preventDefault();

        dispatch(setLoadingNow());

        try {
            await auth.Login(`${ username }@${ companyId }`, password);

            window.localStorage.setItem("username", username);

            window.localStorage.setItem("companyId", companyId);

            const isAdmin = await auth.VerifyAdmin();

            dispatch(setAuthorized({ isAdmin: isAdmin }));
        } catch (ex) {
            dispatch(showMsg({
                title: "Error",
                content: `Unable to login. ${ex.message}`
            }));
        } finally {
            dispatch(setLoadingComplete());
        }
    };

    const readAccRecoveryRequest = () => {
        const url = new URL(window.location);

        const params = url.searchParams;

        let paramAction = "";
        let paramToken = "";
        let paramEmail = "";

        if (params.get("action") !== null && params.get("action") !== undefined) {
            paramAction = params.get("action");
        }

        if (params.get("token") !== null && params.get("token") !== undefined) {
            paramToken = params.get("token");
        }

        if (params.get("email") !== null && params.get("email") !== undefined) {
            paramEmail = params.get("email");
        }

        if (paramAction !== "" && paramToken !== "") {
            setRecToken(paramToken);

            setRecEmail(paramEmail);

            setShowAccRecovery(true);
        }
    };

    const requestPasswordReset = async (e) => {
        e.preventDefault();
        
        dispatch(setLoadingNow());
    
        try {
            const reqUsername = `${ reqUser }@${ reqCompId }`;

            await auth.RequestResetPassword(reqUsername, reqEmail);

            setShowAccRecovery(false);

            dispatch(showMsg({
                title: "Email Sent",
                content: "If entered email is registered to your account, you will receive request token."
            }));
        } catch (ex) {
            dispatch(showMsg({
                title: "Error",
                content: `An error occured during password reset request. ${ ex.message }`
            }));
        } finally {
            dispatch(setLoadingComplete());
        }
      };

    const onSetNewPassword = async (e) => {
        e.preventDefault();

        try {
            if (recPassword !== recPassword2) {
                throw new Error("Password does not matched.");
            }

            if (recPassword.length < 6) {
                throw new Error("Password must be at least 6 character long.");
            }

            if (/[0-9]/.exec(recPassword) === null) {
                throw new Error("Password must contain at least one number (numeric character).");
            }

            if (/[a-zA-z]/.exec(recPassword) === null) {
                throw new Error("Password must contain at least one alphabet character.");
            }

            await auth.SetNewPassword(`${ recUsername }@${ recCompanyId }`, recEmail, recPassword, recToken);

            dispatch(showMsg({
                title: "Success",
                content: "New password has been successfully saved. Please login to continue.",
                id: "PASSWORD_SET_SUCCESS",
                isPromptInput: false
            }));
        } catch (ex) {
            dispatch(showMsg({
                title: "Error",
                content: `Unable to set new password. ${ ex.message }`
            }));
        }
    };

    useEffect(() => {
        readAccRecoveryRequest();
    }, []);

    useEffect(() => {
        if (pageSel.msgId === "PASSWORD_SET_SUCCESS") {
            window.location = "/";
        }
    }, [pageSel.msgResult]);

    return (
        <>
            <div className="bg"></div>
            <div className="banner-container">
                <div>
                    <span className="tagline">Multi-purpose Timestamp Tool</span>
                    <img className="mockup-phone" src="/img/mockup_phone.png"/>
                    <span className="available-on-label">Mobile apps available on:</span>   
                    <div className="store-badge-container">
                        <a href='https://play.google.com/store/apps/details?id=asia.iflexi.iflexiclock&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                            <img className="google-playstore" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                        </a>
                        <a href="https://apps.apple.com/my/app/iflexi-clock/id1552532833">
                            <img className="apple-appstore" alt="iFlexi Clock on Apple App Store" src="/img/appstore.svg"/>
                        </a>
                    </div>                
                </div>
                <img className="banner" src="/img/banner.svg"/>
            </div>
            <form className="side-panel side-panel-login" onSubmit={ onLogin }>
                <div className="form-title">
                    <strong>Login</strong>
                </div>
                <div className="form-row">
                    <TextInput label="Company ID" required hideRequireLabel onChange={(e) => { setCompanyId(e) }}/>
                </div>
                <div className="form-row">
                    <TextInput label="Username" required hideRequireLabel onChange={(e) => { setUsername(e) }}/>
                </div>
                <div className="form-row">
                    <TextInput label="Password" type="password" required hideRequireLabel onChange={(e) => { setPassword(e) }}/>
                </div>
                <div className="form-row form-row-border-top side-panel-login-bottom">
                    <a className="forgot-password-link" href="#" onClick={() => { setShowForgot(true) }}>Forgot Password</a>
                    <Button label="Login" src="/img/login.svg"/>
                </div>
            </form>
            <div 
                className={ showForgot ? "dialog-bg dialog-bg-show" : "dialog-bg" } 
                style={{ height: "100vh", top: "0" }} 
            >
                <div className="dialog" style={{ width: "500px" }}>
                    <div className="dialog-header">
                        <span>Forgot Password</span>
                        <button onClick={() => { setShowForgot(false) }}>
                            <img src="/img/close_black.svg" />
                        </button>
                    </div>
                    <form className="dialog-body" onSubmit={ requestPasswordReset }>
                        <div className="form-row">
                            <span>Email will be sent only if the email address is registered to recover account.</span>
                        </div>
                        <div className="form-row">
                            <TextInput label="Company ID" required onChange={(e) => { setReqCompId(e) }}/>
                            <TextInput label="Username" required onChange={(e) => { setReqUser(e) }}/>
                        </div>
                        <div className="form-row">
                            <TextInput label="Email" type="email" required onChange={(e) => { setReqEmail(e) }}/>
                        </div>
                        <div className="form-row form-row-border-top">
                            <div className="filler"></div>
                            <Button label="Recover" type="submit" src="/img/email.svg"/>
                            <CancelButton onClick={() => { setShowForgot(false) }}/>
                        </div>
                    </form>
                </div>
            </div>
            <div className={ showAccRecovery ? "dialog-bg dialog-bg-show" : "dialog-bg" } 
                style={{ height: "100vh", top: "0" }}
            >
                <div className="dialog" style={{ width: "500px" }}>
                    <div className="dialog-header">
                        <span>Account Recovery</span>
                        <button onClick={() => { setShowAccRecovery(false) }}>
                            <img src="/img/close_black.svg" />
                        </button>
                    </div>
                    <form className="dialog-body" onSubmit={ onSetNewPassword }>
                        <div className="form-row">
                            <TextInput label="Company ID" required hideRequireLabel onChange={(e) => { setRecCompanyId(e) }}/>
                            <TextInput label="Username" required hideRequireLabel onChange={(e) => { setRecUsername(e) }}/>
                        </div>
                        <div className="form-row">
                            <TextInput label="Password" type="password" required hideRequireLabel onChange={(e) => { setRecPassword(e) }}/>
                            <TextInput label="Confirm Password" type="password" required hideRequireLabel onChange={(e) => { setRecPassword2(e) }}/>
                        </div>
                        <div className="form-row">
                            <ul>
                                <li>Password must be at least 6 characters long.</li>
                                <li>Must contain alphabet and numbers (alphanumeric).</li>
                            </ul>
                        </div>
                        <div className="form-row form-row-border-top">
                            <div className="filler"></div>
                            <Button label="Confirm" type="submit" src="/img/check.svg"/>
                            <CancelButton onClick={() => { setShowAccRecovery(false) }}/>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}