import { 
  useDispatch,
  useSelector
} from "react-redux";

import {
  useState,
  useEffect
} from "react";

import {
  setAuthorized,
  setUnauthorized,
  setUsername as setAuthUsername,
  authSelector
} from "./store/authSlice";

import {
  setPage,
  showMsg,
  hideMsg,
  okMsg,
  setShowManual,
  setLoadingComplete,
  pageSelector
} from "./store/pageSlice";

import { 
  Button,
  TextInput,
  SaveButton,
  CancelButton,
  ComboBox
} from "./components/Input";

import AutoTable from "./components/AutoTable";

import { FormatCompDate } from "./miscfunction/DateTimeFormat";

import * as auth from "./api/auth";

import EmployeeMaster from "./pages/EmployeeMaster";
import EmployeeGroup from "./pages/EmployeeGroup";
import GeneralSetup from "./pages/GeneralSetup";
import ClockDataTag from "./pages/ClockDataTag";
import Locations from "./pages/Locations";
import UserManagement from "./pages/UserManagement";
import ExportTemplate from "./pages/ExportTemplate";
import ClockData from "./pages/ClockData";
import Login from "./pages/Login";
import QRCodeGenerator from "./pages/QRCodeGenerator";
import Team from "./pages/Team";

import './App.css';

const appVersion = "2.13.5";

const releaseNoteVersion = 7;

export default function App() {
  const dispatch = useDispatch();
  const authSel = useSelector(authSelector);
  const pageSel = useSelector(pageSelector);

  const [username, setUsername] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [companyName, setCompanyName] = useState("");

  const [chgPwd, setChgPwd] = useState("");
  const [chgPwd2, setChgPwd2] = useState("");

  const [showMenu, setShowMenu] = useState(false);
  const [showAccSetting, setShowAccessSetting] = useState(false);

  const [companyContract, setCompanyContract] = useState(defaultCompanyContract);
  const [showCompContract, setShowCompContract] = useState(false);

  const [showReleaseNote, setShowReleaseNote] = useState(false);

  const [showUsage, setShowUsage] = useState(false);
  const [selPeriodUsage, setSelPeriodUsage] = useState("");
  const [dsUsage, setDsUsage] = useState();
  const [usageHeadcount, setUsageHeadcount] = useState(0);
  const [usageTrans, setUsageTrans] = useState(0);
  const [usageDetails, setUsageDetail] = useState([]);

  const getUsagePeriods = () => {
    const dt = new Date();

    dt.setHours(0);
    dt.setMinutes(0);
    dt.setSeconds(0);
    dt.setMilliseconds(0);

    dt.setDate(dt.getDate() - 1);
    dt.setDate(1);

    let result = [];

    for (let i = 0; i < 6; i++) {
      dt.setDate(dt.getDate() - 1);
      dt.setDate(1);

      result.push((
        <option key={`period_${dt}`} value={dt.toString()}>{`${dt.getDate()}/${dt.getMonth() + 1}/${dt.getFullYear()}`}</option>
      ));
    }

    return result;
  };

  const logoutNow = async () => {
    try {
      await auth.Logout();
    } catch {
    } finally {
      window.localStorage.removeItem("username");

      window.localStorage.removeItem("companyId");

      document.cookie = "name=companyId;value=;expires=1970-01-01;";
      document.cookie = "name=username;value=;expires=1970-01-01;";
      document.cookie = "name=sessionToken;value=;expires=1970-01-01;";

      dispatch(setUnauthorized());
    }
  };

  const changePassword = async (e) => {
    e.preventDefault();

    if (!showAccSetting) {
      return;
    }

    try {
      if (chgPwd !== chgPwd2) {
        throw new Error("Password does not matched!");
      }

      await auth.ChangePassword(username, chgPwd);

      setShowAccessSetting(false);

      dispatch(showMsg({
        title: "Password Updated",
        content: "Your password has been updated successfully! Please re-login to continue.",
        id: "UPD_USER"
      }));
    } catch (ex) {
      dispatch(showMsg({
        title: "Error",
        content: `Unable to update password. ${ ex.message }`
      }));
    }
  };

  const checkDisplayReleaseNote = () => {
    const localStorage = window.localStorage;

    const noteVersion = localStorage.getItem("release_note");

    let isOutdatedNoteVersion = false;

    if (noteVersion === null) {
      setShowReleaseNote(true);
      isOutdatedNoteVersion = true;
    } else {
      if (Number(noteVersion) < releaseNoteVersion) {
        setShowReleaseNote(true);
        isOutdatedNoteVersion = true;
      }
    }

    if (isOutdatedNoteVersion) {
      localStorage.setItem("release_note", releaseNoteVersion);
    }
  };

  const fetchUsage = async (ACompanyId) => {
    try {
      const dtStart = new Date();

      dtStart.setDate(1);
      dtStart.setHours(0);
      dtStart.setMinutes(0);
      dtStart.setSeconds(0);
      dtStart.setMilliseconds(0);

      const dtEnd = new Date(dtStart);

      dtStart.setMonth(dtStart.getMonth() - 6);

      const appUsage = await auth.GetUsage(dtStart, dtEnd, ACompanyId);

      setDsUsage(appUsage);

      setSelPeriodUsage("");
    } catch (ex) {
      console.error(`Error during app usage fetching. ${ex}`);
    }
  };

  useEffect(() => {
    (
      async () => {
        try {
          await auth.VerifyLogin();

          const isAdmin = await auth.VerifyAdmin();

          dispatch(setAuthorized({ isAdmin: isAdmin }));
        } catch (ex) {
          console.error(`Error during app startup. ${ex}`);
        } finally {
          dispatch(setLoadingComplete());
        }
      }
    )();
  }, []);

  useEffect(() => { setShowMenu(false) }, [pageSel.page]);

  useEffect(() => {
    if (!authSel.isAuthorized) {
      return;
    }

    (
      async () => {
        const usr = window.localStorage.getItem("username");
        const compId = window.localStorage.getItem("companyId");

        setUsername(usr);

        setCompanyId(compId);

        setCompanyName(compId);

        dispatch(setAuthUsername(usr));
        
        dispatch(setPage("HOME"));

        try {
          const compContract = await auth.GetCompanyContract();

          setCompanyContract(compContract);

          await fetchUsage(compId);

          checkDisplayReleaseNote();
        } catch (ex) {
          console.error("Company contract failed", ex);
        }
      }
    )();
  }, [authSel.isAuthorized]);

  useEffect(() => {
    if (!pageSel.msgResult) {
      dispatch(hideMsg());
      return;
    }

    if (pageSel.msgId === "UPD_USER") {
      setChgPwd("");

      setChgPwd2("");

      dispatch(setUnauthorized());
    }

    dispatch(hideMsg());
  }, [pageSel.msgResult]);

  useEffect(() => {
    if (!showAccSetting) {
      return;
    }

    setChgPwd("");

    setChgPwd2("");
  }, [showAccSetting]);

  useEffect(() => {
    if (selPeriodUsage === "") {
      setUsageDetail([]);

      setUsageHeadcount(0);
      setUsageTrans(0);

      return;
    }

    let res = [];

    const dtSelPeriod = new Date(selPeriodUsage);

    for (const key in dsUsage) {
      const row = dsUsage[key];
      const dtRow = new Date(row.period);

      if (dtRow.getTime() !== dtSelPeriod.getTime())
        continue;

      setUsageHeadcount(row.headcount);
      setUsageTrans(row.totalTransaction);

      res = row.details;
    }

    setUsageDetail(res);
  }, [selPeriodUsage]);

  return (
    <>
      {
        authSel.isAuthorized === false && (
          <Login />
        )
      }
      {
        authSel.isAuthorized && (
          <nav>
            <div>
              <img className="nav-logo" src="/img/navbar.svg" alt="navbar_logo" />
              {
                authSel.isAdmin && (
                  <>
                    <button className="nav-button" onClick={() => { setShowMenu(!showMenu) }}>
                      <img src={ showMenu ? "/img/chevron_left.svg" : "/img/chevron_right.svg" } />
                    </button>
                    <a href="#" className="nav-company-name" onClick={() => { setShowCompContract(true) }}>
                      { companyContract.companyName }
                    </a>
                  </>
                )
              }
              {
                !authSel.isAdmin && (
                  <span className="nav-company-name" style={{ cursor: "auto" }}>{ companyContract.companyName }</span>
                )
              }
            </div>
            <div>
              {
                authSel.isAdmin && (
                  <>
                    <button className="nav-account-button" onClick={() => { setShowAccessSetting(true) }}>
                      <img src="/img/account_circle.svg" />
                      { username }
                    </button>
                    <button className="nav-manual-button" onClick={() => { dispatch(setShowManual(true)); setShowMenu(false); }}>
                      <img src="/img/help.svg" />
                    </button>
                  </>
                )
              }
              <button className="nav-logout-button" onClick={ logoutNow }>
                <img src="/img/logout.svg" />
              </button>
            </div>
          </nav>
        )
      }
      {
        authSel.isAuthorized && authSel.isAdmin && (
          <>
            <div className={ !showMenu ? "nav-menu" : "nav-menu nav-menu-pin"}>
              <strong>Home</strong>
              <a className={ !showMenu ? "nav-link" : "nav-link nav-menu-show-item" } onClick={() => { dispatch(setPage("HOME")) }} href="#">
                <img src={ pageSel.page === "HOME" ? "/img/schedule_hover.svg" : "/img/schedule.svg" }/>
                <span className={ pageSel.page === "HOME" ? "nav-link-active" : "" }>Clock Data</span>
              </a>

              <strong>Employee</strong>
              <a className="nav-link" onClick={() => { dispatch(setPage("EMP_MASTER")) }} href="#">
                <img src={ pageSel.page === "EMP_MASTER" ? "/img/people_hover.svg" : "/img/people.svg" } />
                <span className={ pageSel.page === "EMP_MASTER" ? "nav-link-active" : "" }>Employee Master</span>
              </a>
              <a className="nav-link" onClick={() => { dispatch(setPage("EMP_GROUP")) }} href="#">
                <img src={ pageSel.page === "EMP_GROUP" ?  "/img/groups_hover.svg" : "/img/groups.svg" } />
                <span className={ pageSel.page === "EMP_GROUP" ? "nav-link-active" : "" }>Employee Group</span>
              </a>
              <a className="nav-link" onClick={() => { dispatch(setPage("TEAM")) }} href="#">
                <img src={ pageSel.page === "TEAM" ?  "/img/diversity_hover.svg" : "/img/diversity.svg" } />
                <span className={ pageSel.page === "TEAM" ? "nav-link-active" : "" }>Team</span>
              </a>

              <strong>Preferences</strong>
              <a className="nav-link" onClick={() => { dispatch(setPage("GEN_SETUP")) }} href="#">
                <img src={ pageSel.page === "GEN_SETUP" ? "/img/settings_hover.svg" : "/img/settings.svg" } />
                <span className={ pageSel.page === "GEN_SETUP" ? "nav-link-active" : "" }>General Setup</span>
              </a>
              <a className="nav-link" onClick={() => { dispatch(setPage("CLK_DATATAG")) }} href="#">
                <img src={ pageSel.page === "CLK_DATATAG" ? "/img/local_offer_hover.svg" : "/img/local_offer.svg" } />
                <span className={ pageSel.page === "CLK_DATATAG" ? "nav-link-active" : "" }>Clock Data Tag</span>
              </a>
              <a className="nav-link" onClick={() => { dispatch(setPage("EXPT_TEMPLATE")) }} href="#">
                <img src={ pageSel.page === "EXPT_TEMPLATE" ? "/img/view_quilt_hover.svg" : "/img/view_quilt.svg" } />
                <span className={ pageSel.page === "EXPT_TEMPLATE" ? "nav-link-active" : "" }>Export Template</span>
              </a>
              <a className="nav-link" onClick={() => { dispatch(setPage("LOCATIONS")) }} href="#">
                <img src={ pageSel.page === "LOCATIONS" ? "/img/location_on_hover.svg" : "/img/location_on.svg" } />
                <span className={ pageSel.page === "LOCATIONS" ? "nav-link-active" : "" }>Locations</span>
              </a>
              <a className="nav-link" onClick={() => { dispatch(setPage("USR_MANAGEMENT")) }} href="#">
                <img src={ pageSel.page === "USR_MANAGEMENT" ? "/img/manage_accounts_hover.svg" : "/img/manage_accounts.svg" } />
                <span className={ pageSel.page === "USR_MANAGEMENT" ? "nav-link-active" : "" }>User Management</span>
              </a>

              <strong>Help</strong>
              <a className="nav-link" onClick={() => { setShowReleaseNote(true); setShowMenu(false); }} href="#">
                <span>Release Note</span>
              </a>
            </div>

            <div className="page-container">
              { pageSel.page === "HOME" && ( <ClockData /> ) }
              { pageSel.page === "EMP_MASTER" && ( <EmployeeMaster /> )}
              { pageSel.page === "EMP_GROUP" && ( <EmployeeGroup /> ) }
              { pageSel.page === "GEN_SETUP" && ( <GeneralSetup /> ) }
              { pageSel.page === "CLK_DATATAG" && ( <ClockDataTag /> ) }
              { pageSel.page === "LOCATIONS" && ( <Locations /> ) }
              { pageSel.page === "USR_MANAGEMENT" && ( <UserManagement /> ) }
              { pageSel.page === "EXPT_TEMPLATE" && ( <ExportTemplate /> ) }
              { pageSel.page === "TEAM" && ( <Team /> ) }
            </div>
          </>
        )
      }
      {
        authSel.isAuthorized && !authSel.isAdmin && (
          <QRCodeGenerator />
        )
      }
      {
        pageSel.isLoading && (
          <div className="loading-bg">
            <div className="loading-container">
              <span>Loading...</span>
              <div></div>
            </div>
          </div>
        )
      }
      <div className={ pageSel.msgContent !== "" ? "message-bg message-bg-show" : "message-bg" }>
        <div className={ pageSel.msgContent !== "" ? "message-box message-box-show" : "message-box" }>
          <div className="message-box-header">
            <span>{ pageSel.msgTitle }</span>
            <button onClick={() => { dispatch(hideMsg()) }}>
              <img src="/img/close_white.svg" />
            </button>
          </div>
          <div className="message-box-body">
            <span>{ pageSel.msgContent }</span>
          </div>
          <div className="message-box-footer">
            {
              !pageSel.msgPromptInput && (
                <Button label="OK" onClick={() => {
                  if (pageSel.msgId === "") {
                    dispatch(hideMsg());
                  } else {
                    dispatch(okMsg());
                  } 
              }} />
              )
            }
            {
              pageSel.msgPromptInput && (
                <>
                  <Button label="Yes" onClick={() => { dispatch(okMsg()) }} />
                  <Button label="No" onClick={() => { dispatch(hideMsg()) }} />
                </>
              )
            }
          </div>
        </div>
      </div>
      <div className={ showAccSetting ? "dialog-bg dialog-bg-show" : "dialog-bg" }>
        <div className="dialog" style={{ width: "500px" }}>
          <div className="dialog-header">
            <span>Account Settings</span>
            <button onClick={() => { setShowAccessSetting(false) }}>
              <img src="/img/close_black.svg" />
            </button>
          </div>
          <form className="dialog-body" onSubmit={ changePassword }>
            <div className="form-row">
              <strong>Change Password</strong>
            </div>
            <div className="form-row">
              <TextInput 
                label="Password" 
                type="password" 
                required 
                hideRequireLabel  
                onChange={(e) => { setChgPwd(e) }}
                value={ chgPwd }
              />
              <TextInput 
                label="Confirm Password" 
                type="password" 
                required 
                hideRequireLabel 
                onChange={(e) => { setChgPwd2(e) }}
                value={ chgPwd2 }
              />
            </div>
            <div className="form-row form-row-border-top">
              <div></div>
              <div className="form-row" style={{ padding: 0 }}>
                <SaveButton />
                <CancelButton onClick={() => { setShowAccessSetting(false) }}/>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className={ showCompContract ? "dialog-bg dialog-bg-show" : "dialog-bg" }>
        <div className="dialog" style={{ width: "500px" }}>
          <div className="dialog-header">
            <span>Company Contract</span>
            <button onClick={() => { setShowCompContract(false) }}>
              <img src="/img/close_black.svg" />
            </button>
          </div>
          <div className="dialog-body">
            <div className="form-row">
              <TextInput label="Company ID" value={ companyContract.companyID } readonly />
              <TextInput 
                label="Contract End" 
                value={ FormatCompDate(new Date(companyContract.contractEndDate)) } 
                readonly 
              />
            </div>
            <div className="form-row">
              <TextInput label="Company Name" value={ companyContract.companyName } readonly />
            </div>
            <div className="form-row">
              <Button label="View Application Usage" src="/img/receipt_long.svg" onClick={() => { setShowCompContract(false); setShowUsage(true); }}/>
            </div>
          </div>
        </div>
      </div>

      <div className={ showUsage ? "dialog-bg dialog-bg-show" : "dialog-bg" }>
        <div className="dialog" style={{ width: `${0.7 * window.screen.width}px`  }}>
          <div className="dialog-header">
            <span>Application Usage</span>
            <button onClick={() => { setShowUsage(false) }}>
              <img src="/img/close_black.svg" />
            </button>
          </div>
          <div className="dialog-body">
            <div className="form-row">
              <ComboBox label="Period" onChange={(e) => { setSelPeriodUsage(e.target.value) }} horizontal opts={ getUsagePeriods() }/>
            </div>
            <div className="form-row">
              <TextInput label="Total Active Headcount" value={usageHeadcount} readonly/>
              <TextInput label="Total Transactions" value={usageTrans} readonly/>
            </div>
            <AutoTable th={ thUsage } noFilter={true}>
              {
                usageDetails.length > 0 && 
                usageDetails.map((row) => (
                  <UsageRow datarow={row}/>
                ))
              }
            </AutoTable>
          </div>
        </div>
      </div>

      <div className={ showReleaseNote ? "dialog-bg dialog-bg-show" : "dialog-bg" }>
        <div className="dialog" style={{ width: "80vw" }}>
          <div className="dialog-header">
            <span>{ `Release Note - v${appVersion}` }</span>
            <button onClick={() => { setShowReleaseNote(false) }}>
              <img src="/img/close_black.svg" />
            </button>
          </div>
          <div className="dialog-body-free">
          <h3>What's new?</h3>
            <ul>
              <li>
                <strong>Supervisor Feature Access</strong>
                <p>New options for supervisor's subordinate authority control available in <strong>Employee Master</strong>.</p>
              </li>
              <li>
                <strong>Manage team from web application</strong>
                <p>Team now can be manage from section "Team" on iFlexi Clock web application.</p>
              </li>
              <li>
                <strong>Use location description as address</strong>
                <p>With this option enabled, the iFlexi Clock mobile app will use the location's defined address if users is within the location radius.</p>
              </li>
              <li>
                <strong>Map layers option</strong>
                <p>Map layers now available in all map view. You can choose map layer to ease your map viewing.</p>
              </li>
              <li>
                <strong>Export filename</strong>
                <p>Exported clock data will be exported based on export template name. To export to CSV file directly, include .csv at the end of export template name.</p>
              </li>
              <li>
                <strong>Application usage view</strong>
                <p>To view application usage, click on your company name at the top-bar. Then click on "View Application Usage".</p>
              </li>
              <li>
                <strong>Use coordinate to search location</strong>
                <p>Now you can use coordinate to search location. To perform location search using coordinate, paste coordinate on 
                  location search box and click on magnifying glass button on the right side.
                </p>
              </li>
              <li>
                <strong>Fence coverage radius extend to 10KM</strong>
                <p>Fence coverage radius can be set up to 10KM.</p>
              </li>
              <li>
                <strong>New offline policy*</strong>
                <p>iFlexi Clock now allows users to clock in offline mode. To enable offline mode, go to "Employee Group" and select a group. 
                  Then, go to the security tab and click on "Allow offline clocking" to enable offline mode.</p>
                <p>iFlexi Clock provides two ways to perform clocking in offline mode:</p>
                  <ul>
                    <li><strong>"Allow self-clocking" enabled</strong> - this allows users to clock as usual. 
                      However, there is a downside to this option: it does not validate the device clock time.
                    </li>
                    <li><strong>"Allow self-clocking" disabled</strong> - this option requires a supervisor to scan a subordinate's QR code generated
                      when the user started to perform clocking. This option uses the clock time on the supervisor's device clock time.
                    </li>
                  </ul>
              </li>
              <li>
                <strong>Mandate PIN code authentication*</strong>
                <p>This option allows organizations to enforce a secure environment for users while using the iFlexi Clock mobile app. This option 
                  will force users to create a PIN code. Once a PIN code is created, the iFlexi Clock mobile app will prompt for PIN code entry. If a user's 
                  device has a biometric reader integrated, they can opt in to biometric authentication instead of PIN code entry.
                </p>
              </li>
            </ul>
            <p>* These options are only available on iFlexi Clock v1.5.0 and later.</p> 
            <h3>Where to start?</h3>
            <p>You can start navigating through iFlexi Clock by clicking <strong>"menu"</strong> button on top navigation bar.</p>
            <img className="diagram" style={{ width: "423px", marginBottom: "50px" }} src="/img/manual/where_start.png" />
          </div>
        </div>
      </div>
    </>
  );
}

const defaultCompanyContract = {
  id: "-1",
  companyID: "",
  productID: "",
  contractStartDate: new Date().toISOString(),
  contractEndDate: new Date().toISOString(),
  lastUpdate: new Date().toISOString(),
  companyName: "",
  subModules: [],
  configurations: [],
  isTrial: false
};

const thUsage = [
  { id: 0, name: "Emp. #", span: 1, width: "20%", type: "text" },
  { id: 1, name: "Name", span: 1, width: "50%", type: "text" },
  { id: 2, name: "# Trans.", span: 1, width: "30%", type: "text" }
];

function UsageRow(props) {
  return (
      <tr className="autotable-tr" scope="row">
          <td className="autotable-td" style={{ width: "20%", textAlign: "left" }}>{ props.datarow.empNo }</td>
          <td className="autotable-td" style={{ width: "50%", textAlign: "left" }}>{ props.datarow.name }</td>
          <td className="autotable-td" style={{ width: "30%", textAlign: "right" }}>{ props.datarow.totalTransaction }</td>
      </tr>
  );
}