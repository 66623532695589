import {
    useState,
    useEffect
} from "react";

import QRCode from "react-qr-code";

import { GenerateQRData } from "../api/qrCode";

import "../App.css";
import "./QRCodeGenerator.css";

export default function QRCodeGenerator() {
    const [code, setCode] = useState("0");

    const [hwBgw, setHwBgw] = useState(null);

    const [isRefresh, setIsRefresh] = useState(false);

    const reloadQR = async (isForce = false) => {
        try {
            const dt = new Date();
    
            if (dt.getSeconds() === 0 || isForce) {
                try {
                    const compId = window.localStorage.getItem("companyId");

                    const res = await GenerateQRData(compId);

                    setCode(res);
                } catch (ex) {
                    console.error("QR Code Gen ERROR", ex);
                } 
            }
        } catch {

        }
    };

    useEffect(() => {
        (
            async () => {
                await reloadQR(true);
            }
        )();

        const hw = setInterval(reloadQR, 800);

        setHwBgw(hw);

        return () => {
            clearInterval(hw);
            setHwBgw(null);
        };
    }, []);

    return (
        <div className="page-container">
            <strong className="title">QR Code Generator</strong>
            <div className="qrcode-container">
                <QRCode value={ code } size={ 250 } viewBox={`0 0 250 250`} style={{ padding: "20px", borderRadius: "20px", boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.3)" }}/>
            </div>
        </div>
    );
}