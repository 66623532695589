import {
    useState,
    useEffect
} from "react";

import {
    useDispatch,
    useSelector
} from "react-redux";

import {
    Button,
    SaveButton,
    CancelButton,
    TextInput
} from "../components/Input";

import {
    GetClockDataTag,
    AddClockDataTag,
    UpdateClockDataTag,
    DeleteClockDataTag
} from "../api/clockDataTag";

import {
    showMsg,
    hideMsg,
    setLoadingNow,
    setLoadingComplete,
    pageSelector
} from "../store/pageSlice";

import "../App.css"

import Help from "./Help";

export default function ClockDataTag() {
    const dispatch = useDispatch();
    const pageSel = useSelector(pageSelector);

    const [ds, setDs] = useState([]);
    const [lst, setLst] = useState();

    const [selClockDataTag, setSelClockDataTag] = useState(defaultClockDataTag);

    const [isCancel, setIsCancel] = useState(false);

    const [search, setSearch] = useState("");

    const refreshList = async () => {
        dispatch(setLoadingNow());

        try {
            const res = await GetClockDataTag();
            setDs(res);
        } catch (ex) {
            dispatch(showMsg({
                title: "Error",
                content: `Unable to retrieve clock data tag. ${ ex.message }`
            }));
        } finally {
            dispatch(setLoadingComplete());
        }
    };

    const renderList = () => {
        let dsTemp = ds.map((row) => row);

        if (search !== "") {
            dsTemp = dsTemp.filter((row) => {
                const srch = search.toLowerCase();

                return row.label.toLowerCase().indexOf(srch) > -1 ||
                    row.description.toLowerCase().indexOf(srch) > -1;
            });
        }

        const lst = dsTemp.map((row) => (
            <div key={ row.id } className="listing-row" onClick={() => { setSelClockDataTag(row) }}>
                <strong>{ row.label }</strong>
                <span>{ row.description }</span>
            </div>
        ));

        setLst(lst);
    };

    const onCreateNew = () => {
        setSelClockDataTag(defaultClockDataTag);
    };

    const onDelete = () => {
        if (selClockDataTag.id === "-1") {
            return;
        }

        dispatch(showMsg({
            title: "Deleting...",
            content: `Are you sure you want delete tag ${ selClockDataTag.label }?`,
            id: "DEL_CLK_TAG",
            isPromptInput: true
        }));
    };

    const onSave = async (e) => {
        e.preventDefault();

        if (isCancel) {
            return;
        }

        dispatch(setLoadingNow());

        try {
            if (selClockDataTag.id === "-1") {
                const dat = {
                    tag: selClockDataTag.tag,
                    label: selClockDataTag.label,
                    description: selClockDataTag.description
                };

                await AddClockDataTag(dat);
            } else {
                await UpdateClockDataTag(selClockDataTag);
            }

            await refreshList();

            dispatch(showMsg({
                title: "Success",
                content: "Changes saved successfully!"
            }));
        } catch (ex) {
            dispatch(showMsg({
                title: "Error",
                content: `Saving changes failed. ${ ex.message }`
            }));
        } finally {
            dispatch(setLoadingComplete());
        }
    };

    const onFieldChange = (e, field) => {
        if (field === "TAG") {
            setSelClockDataTag({
                ...selClockDataTag,
                tag: e
            });
        } else if (field === "LABEL") {
            setSelClockDataTag({
                ...selClockDataTag,
                label: e
            });
        } else if (field === "DESCRIPTION") {
            setSelClockDataTag({
                ...selClockDataTag,
                description: e
            });
        }
    };

    useEffect(() => {
        (
            async () => {
                await refreshList();
            }
        )();
    }, []);

    useEffect(() => { renderList() }, [ds, search]);

    useEffect(() => {
        if (!pageSel.msgResult) {
            return;
        }

        (
            async () => {
                dispatch(setLoadingNow());

                try {
                    if (pageSel.msgId === "DEL_CLK_TAG") {
                        await DeleteClockDataTag(selClockDataTag.id);    
                        
                        await refreshList();

                        setSelClockDataTag(defaultClockDataTag);
                        
                        dispatch(hideMsg());
                    }
                } catch (ex) {
                    dispatch(showMsg({
                        title: "Error",
                        content: `Unable to proceed. ${ ex.message }`
                    }));
                } finally {
                    dispatch(setLoadingComplete());
                }
            }
        )();
    }, [pageSel.msgResult]);

    useEffect(() => {
        if (!isCancel) {
            return;
        }

        if (selClockDataTag.id === "-1") {
            setSelClockDataTag(defaultClockDataTag);
        } else {
            const res = ds.filter((row) => row.id === selClockDataTag.id);
            
            if (res.length === 0) {
                setSelClockDataTag(defaultClockDataTag);
            } else {
                setSelClockDataTag(res[0]);
            }
        }

        setIsCancel(false);
    }, [isCancel]);

    return (
        <>
            <strong className="title">Clock Data Tag</strong>
            <div className="panel-horizontal">
                <div className="panel-inner panel-inner-start">
                    <Button src="/img/refresh.svg" onClick={ refreshList }/>
                    <Button label="New" src="/img/add.svg" onClick={ onCreateNew }/>
                    <Button label="Delete" src="/img/remove.svg" onClick={ onDelete }/>
                </div>
                <div className="panel-inner panel-inner-end"></div>
            </div>
            <form className="side-panel" onSubmit={ onSave }>
                <div className="form-section">
                    <strong>Details</strong>
                </div>
                <div className="form-row">
                    <TextInput label="Tag" readonly={ selClockDataTag.id !== "-1" } value={ selClockDataTag.tag } onChange={(e) => { onFieldChange(e, "TAG") }} required />
                    <TextInput label="Label" required value={ selClockDataTag.label } onChange={(e) => { onFieldChange(e, "LABEL") }} />
                </div>
                <div className="form-row">
                    <TextInput label="Description" value={ selClockDataTag.description } onChange={(e) => { onFieldChange(e, "DESCRIPTION") }}/>
                </div>
                <div className="form-row form-row-border-top">
                    <div></div>
                    <div></div>
                    <div className="form-row" style={{ padding: 0 }}>
                        <SaveButton /> 
                        <CancelButton onClick={() => { setIsCancel(true) }}/>
                    </div>
                </div>
            </form>
            <div className="listing">
                <input className="listing-search-box" type="text" placeholder="search" value={ search } onChange={(e) => { setSearch(e.target.value) }} />
                <div>{ lst }</div>
            </div>
            <Help sectionName="Clock Data Tag">
                <h3>What is Clock Data Tag</h3>
                <p>Clock data tag is tagging system or way to categorised clock data to its category. As example, an employee 
                    wanted to clock in, so one must select clock data tag "clock in". This will ease applying clock data filtering.
                </p>
                <h3>Create New Clock Data Tag</h3>
                <ul>
                    <li>Click on <strong>"New"</strong> button.</li>
                    <li>Enter particulars and click <strong>"Save"</strong> button to save changes.</li>
                </ul>
                <h3>Delete Clock Data Tag</h3>
                <ul>
                    <li>Click on desired record.</li>
                    <li>Click on <strong>"Delete"</strong> button.</li>
                </ul>
            </Help>
        </>
    );
}

const defaultClockDataTag = {
    id: "-1",
    tag: "",
    label: "",
    description: ""
};