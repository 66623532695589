import dbList from "../DBList.json";

const {
    api_server,
    auth
} = dbList.server;

const url = api_server + auth;

export async function GetUsage(dtStart, dtEnd, ACompanyId) {
    const httpRes = await fetch(`https://bill.iflexiclock.com/bill`, {
        method: "POST",
        mode: "cors",
        headers: {
            "accept": "*/*",
            "content-type": "application/json",
            "companyId": ACompanyId
        },
        body: JSON.stringify({
            DateStart: dtStart,
            DateEnd: dtEnd
        })
    });

    if (!httpRes.ok) {
        throw new Error(await httpRes.text());
    }

    const res = await httpRes.json();

    if (res.status === "ERROR")
        throw new Error(res.data);

    return res.data;
}

export async function Login(AUsername, APassword) {
    const httpRes = await fetch(`${ url }/login`, {
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: {
            "accept": "*/*",
            "content-type": "application/json"
        },
        body: JSON.stringify({
            username: AUsername,
            password: APassword
        })
    });

    if (!httpRes.ok) {
        throw new Error(await httpRes.text());
    }
}

export async function Logout() {
    const httpRes = await fetch(`${ url }/logout`, {
        method: "DELETE",
        mode: "cors",
        credentials: "include",
        header: { "accept": "*/*" }
    });

    if (!httpRes.ok) {
        throw new Error(await httpRes.text());
    }
}

export async function VerifyLogin() {
    const httpRes = await fetch(`${ url }/verlogin`, {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: { "accept": "*/*" }
    });

    if (!httpRes.ok) {
        throw new Error(await httpRes.text());
    }
}

export async function VerifyAdmin() {
    const httpRes = await fetch(`${url}/veradmin`, {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: { "accept": "*/*" }
    });

    if (!httpRes.ok) {
        throw new Error(await httpRes.text());
    }

    const res = await httpRes.text();

    return res === "0";
}

export async function ChangePassword(AUsername, APassword) {
    if (/[\dA-Za-z\S]+/.exec(APassword) === null) {
        throw new Error(`Password does not meet criteria. 
        Must have at least either alphabet or numeric or symbols`);
    }

    const httpRes = await fetch(`${ url }/chgpwd`, {
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: {
            "accept": "*/*",
            "content-type": "application/json"
        },
        body: JSON.stringify({
            username: AUsername,
            password: APassword
        })
    });

    if (!httpRes.ok) {
        throw new Error(await httpRes.text());
    }
}

export async function RequestResetPassword(AUsername, AEmail) {
    const httpRes = await fetch(`${ url }/requestreset`, {
        method: "POST",
        mode: "cors",
        headers: { 
            "accept": "*/*",
            "content-type": "application/json"
        },
        body: JSON.stringify({
            username: AUsername,
            email: AEmail
        })
    });

    if (!httpRes.ok) {
        throw new Error(await httpRes.text());
    }
}

export async function SetNewPassword(AUsername, AEmail, APwd, AToken) {
    const httpRes = await fetch(`${ url }/recoverpwd`, {
        method: "POST",
        mode: "cors",
        headers: { 
            "accept": "*/*",
            "content-type": "application/json"
        },
        body: JSON.stringify({
            username: AUsername,
            email: AEmail,
            password: APwd,
            requestToken: AToken
        })
    });

    if (!httpRes.ok) {
        throw new Error(await httpRes.text());
    }
}

// ===== User management

export async function GetUserList() {
    const httpRes = await fetch(`${ url }/listusr`, {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: { "accept": "*/*" }
    });

    if (!httpRes.ok) {
        throw new Error(await httpRes.text());
    }

    return await httpRes.json();
}

export async function UpdateUser(AUser) {
    const httpRes = await fetch(`${ url }/update`, {
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: {
            "accept": "*/*",
            "content-type": "application/json"
        },
        body: JSON.stringify(AUser)
    });

    if (!httpRes.ok) {
        throw new Error(await httpRes.text());
    }
}

export async function AddUser(AUser, ACompanyId) {
    const userRegData = {
        user: {
            username: AUser.username,
            firstName: AUser.firstName,
            lastName: AUser.lastName,
            email: AUser.email,
            accessCode: AUser.accessCode
        },
        companyData: {
            companyId: ACompanyId
        }
    };

    const httpRes = await fetch(`${ url }/create`, {
        method: "PUT",
        mode: "cors",
        credentials: "include",
        headers: {
            "accept": "*/*",
            "content-type": "application/json"
        },
        body: JSON.stringify(userRegData)
    });

    if (!httpRes.ok) {
        throw new Error(await httpRes.text());
    }
}

export async function GetRequestToken(AUsername) {
    const httpRes = await fetch(`${ url }/getreqtokenusr/${ AUsername }`, {
        method: "GET",
        mdoe: "cors",
        credentials: "include",
        headers: { "accept": "*/*" }
    });

    if (!httpRes.ok) {
        throw new Error(await httpRes.text());
    }

    return await httpRes.text();
}

export async function DeleteUser(AId) {
    const httpRes = await fetch(`${ url }/del/${ AId }`, {
        method: "DELETE",
        mode: "cors",
        credentials: "include",
        headers: { "accept": "*/*" }
    });

    if (!httpRes.ok) {
        throw new Error(await httpRes.text());
    }
}

export async function ResetPassword(AUsername) {
    const httpRes = await fetch(`${ url }/resetreqtoken/${ AUsername }`, {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: { "accept": "*/*" }
    });

    if (!httpRes.ok) {
        throw new Error(await httpRes.text());
    }
}

// ===== Company Contract
export async function GetCompanyContract() {
    const httpRes = await fetch(`${ url }/contract`, {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: { "accept": "*/*" }
    });

    if (!httpRes.ok) {
        throw new Error(await httpRes.text());
    }

    return await httpRes.json();
}