import dbList from "../DBList.json";

const {
    api_server,
    qr_code
} = dbList.server;

const url = api_server + qr_code;

export async function GenerateQRData(ACompanyId) {
    const httpRes = await fetch(url, {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: {
            "accept": "*/*",
            "Akey": ACompanyId
        }
    });

    if (!httpRes.ok) {
        throw new Error(await httpRes.text());
    }

    return await httpRes.text();
}