import {
    useState,
    useEffect
} from "react";

import {
    useDispatch,
    useSelector
} from "react-redux";

import {
    Button,
    SaveButton,
    CancelButton,
    Slider,
    TextInput,
    CheckBox
} from "../components/Input";

import { 
    showMsg,
    hideMsg,
    setLoadingNow,
    setLoadingComplete,
    pageSelector 
} from "../store/pageSlice";

import {
    GetLocations,
    AddLocation,
    UpdateLocation,
    DeleteLocation
} from "../api/location";

import Help from "./Help";

import "../App.css";
import "./Locations.css";
import "azure-maps-control/dist/atlas.min.css";

const Atlas = require("azure-maps-control");

export default function Locations() {
    const dispatch = useDispatch();
    const pageSel = useSelector(pageSelector);

    const [ds, setDs] = useState([]);

    const [lst, setLst] = useState();
    const [lstSearchMap, setLstSearchMap] = useState([]);

    const [map, setMap] = useState(null);
    const [mapLocation, setMapLocation] = useState([ 103.78610070962547, 1.549652396775663 ]);
    const [searchPoint, setSearchPoint] = useState(null);
    const [rad, setRad] = useState(null);
    const [mapPoint, setMapPoint] = useState();

    const [search, setSearch] = useState("");

    const [searchMap, setSearchMap] = useState("");

    const [selLocation, setSelLocation] = useState(defaultLocation);

    const [isCancel, setIsCancel] = useState(false);
    const [isSearchMap, setIsSearchMap] = useState(false);

    const [isKM, setIsKM] = useState(false);

    const refreshList = async () => {
        dispatch(setLoadingNow());

        try {
            const dsLoc = await GetLocations();
            setDs(dsLoc);
        } catch (ex) {
            dispatch(showMsg({
                title: "Error",
                content: `Unable to retrieve location list. ${ ex.message }`
            }));
        } finally {
            dispatch(setLoadingComplete());
        }
    };

    const renderList = () => {
        let dsTemp = ds.map((row) => row);

        if (search !== "") {
            const srch = search.toLowerCase();

            dsTemp = dsTemp.filter((row) => {
                return row.locationCode.toLowerCase().indexOf(srch) > -1 ||
                    row.description.toLowerCase().indexOf(srch) > -1;
            });
        }

        const locLst = dsTemp.map((row) => (
            <div key={ row.id } className="listing-row" onClick={() => { onSelection(row) }}>
                <strong>{ row.locationCode }</strong>
                <span>{ row.description }</span>
            </div>
        ));

        setLst(locLst);
    };

    const onSelection = (e) => {
        const { radiusFence } = e;

        if (Number(radiusFence) >= 1000) {
            setIsKM(true);
        } else {
            setIsKM(false);
        }

        setMapLocation([ Number(e.longitude), Number(e.latitude) ]);
        setSelLocation(e);
    };

    const onFieldChange = (e, field) => {
        if (field === "LOCATION_CODE") {
            setSelLocation({
                ...selLocation,
                locationCode: e
            });
        } else if (field === "DESCRIPTION") {
            setSelLocation({
                ...selLocation,
                description: e
            });
        } else if (field === "LATITUDE") {
            setSelLocation({
                ...selLocation,
                latitude: e
            });
        } else if (field === "LONGITUDE") {
            setSelLocation({
                ...selLocation,
                longitude: e
            });
        } else if (field === "MANDATORY_QR_CODE") {
            setSelLocation({
                ...selLocation,
                mandatoryQRCode: e
            });
        } else if (field === "RADIUS_FENCE") {
            setSelLocation({
                ...selLocation,
                radiusFence: e
            });
        } else if (field === "USE_DESC_ADDRESS") {
            setSelLocation({
                ...selLocation,
                useDescAddress: e
            });
        }
    };

    const onSave = async (e) => {
        e.preventDefault();

        if (isCancel || isSearchMap) {
            return;
        }

        dispatch(setLoadingNow());

        try {
            const lat = mapLocation[1].toString();
            const longi = mapLocation[0].toString();

            const locDat = {
                ...selLocation,
                latitude: lat,
                longitude: longi
            };

            if (selLocation.id === "-1") {
                const newDat = {
                    locationCode: locDat.locationCode,
                    description: locDat.description,
                    latitude: locDat.latitude,
                    longitude: locDat.longitude,
                    mandatoryQRCode: locDat.mandatoryQRCode,
                    radiusFence: locDat.radiusFence,
                    useDescAddress: locDat.useDescAddress
                };

                await AddLocation(newDat);
            } else {
                await UpdateLocation(locDat);
            }

            await refreshList();

            dispatch(showMsg({
                title: "Success",
                content: "Changes successfully saved."
            }));
        } catch (ex) {
            dispatch(showMsg({
                title: "Error",
                content: `Unable to save changes. ${ ex.message }`
            }));
        } finally {
            dispatch(setLoadingComplete());
        }
    };

    const onMapClick = (e) => {
        const coord = e.position;

        if (!Array.isArray(coord)) {
            dispatch(showMsg({
                title: "Error",
                content: "An error occured during map navigation."
            }));
        } else {
            setMapLocation([Number(coord[0]), Number(coord[1])]);
        }
    };

    const onSearchMap = async () => {
        dispatch(setLoadingNow());

        try {
            const reqUrl = `https://atlas.microsoft.com/search/poi/json?
                &subscription-key=C_Vmoi1XoLlF2st8DECCgQ16w7vPJrjpy4-N3TY5P7M
                &api-version=1.0&language=en-US&query=${searchMap}`;

            const res = await fetch(reqUrl);

            if (!res.ok) {
                throw new Error(await res.text());
            }

            let searchRes = await res.json();

            if (searchRes.results === undefined) {
                dispatch(showMsg({
                    title: "",
                    content: "No search result found."
                }));

                return;
            }

            searchRes = searchRes.results;

            if (searchRes.length === 0) {
                dispatch(showMsg({
                    title: "",
                    content: "No search result found."
                }));

                return;
            }

            searchRes = searchRes.filter((row) => row.type === "POI");

            const lst = searchRes.map((row) => (
                <div key={ row.id } className="listing-row" onClick={() => { onSearchMapClick([ Number(row.position.lon), Number(row.position.lat) ]) }}>
                    <strong>{ row.poi.name }</strong>
                    <span>{ row.address.freeformAddress }</span>
                </div>
            ));

            setLstSearchMap(lst);
        } catch (ex) {
            dispatch(showMsg({
                title: "Error",
                content: `An error occured during map search. ${ ex.message }`
            }));
        } finally {
            setIsSearchMap(false);
            dispatch(setLoadingComplete());
        }
    };

    const onSearchMapClick = (e) => {
        map.setCamera({
            center: e,
            zoom: 18,
            duration: 1000,
            type: "fly"
        });

        setMapLocation(e);
    };

    const onDelete = () => {
        if (selLocation.id === "-1") {
            return;
        }

        dispatch(showMsg({
            title: "Deleting...",
            content: `Are you sure want to delete location ${ selLocation.locationCode }?`,
            id: "DEL_LOCATION",
            isPromptInput: true
        }));
    };

    const onCloseSearchMap = () => {
        setLstSearchMap([]);
    };

    const initMap = () => {
        try {
            const mapObj = new Atlas.Map("map", {
                view: "Auto",
                center: mapLocation,
                zoom: 15,
                language: "en-US",
                authOptions: {
                    authType: "subscriptionKey",
                    subscriptionKey: "C_Vmoi1XoLlF2st8DECCgQ16w7vPJrjpy4-N3TY5P7M"
                }
            });

            mapObj.events.add("ready", () => {
                mapObj.controls.add(new Atlas.control.ZoomControl(), { position: "bottom-right" });

                mapObj.controls.add(new Atlas.control.StyleControl({
                    mapStyles: ['road', 'grayscale_dark', 'night', 'road_shaded_relief', 'satellite', 'satellite_road_labels'],
                    layout: 'list'
                  }), {
                    position: 'top-right'
                  });  

                // ----- Location Radius

                const dsPolygon = new Atlas.source.DataSource();

                const radPoint = new Atlas.Shape(new Atlas.data.Point(mapLocation), null, {
                    subType: "Circle",
                    radius: 10
                });

                dsPolygon.add(radPoint);

                mapObj.sources.add(dsPolygon);

                const polyLayer = new Atlas.layer.PolygonLayer(dsPolygon, null, {
                    fillColor: "#00b1b6",
                    fillOpacity: 0.3
                });

                mapObj.layers.add(polyLayer);

                setRad(radPoint);

                // ----- Search pointer

                const dsSearch = new Atlas.source.DataSource();

                const srchLayer = new Atlas.layer.BubbleLayer(dsSearch, null, {
                    radius: 5,
                    strokeColor: "#0093b6",
                    strokeWidth: 6,
                    color: "white"
                });

                mapObj.sources.add(dsSearch);

                mapObj.layers.add(srchLayer);

                const srchPoint = new Atlas.Shape(new Atlas.data.Point([]));

                dsSearch.add(srchPoint);

                setSearchPoint(srchPoint);

                // ----- Location point

                const ds = new Atlas.source.DataSource();

                mapObj.sources.add(ds);

                const layer = new Atlas.layer.SymbolLayer(ds, null);

                mapObj.layers.add(layer);

                const tPoint = new Atlas.Shape(new Atlas.data.Point(mapLocation));

                ds.add(tPoint);                

                setMapPoint(tPoint);

                mapObj.events.add("click", onMapClick);

                setMap(mapObj);
            });

            setMap(mapObj);
        } catch (ex) {
            console.error("Error init", ex);
        }
    };

    useEffect(() => {
        (
            async () => {
                initMap();
                await refreshList();
            }
        )();
    }, []);

    useEffect(() => { renderList() }, [ds, search]);

    useEffect(() => {
        if (map === null || map === undefined || 
            mapPoint === null || mapPoint === undefined ||
            rad === null || rad === undefined) {
            return;
        }

        map.setCamera({
            center: mapLocation,
            zoom: 18,
            duration: 1000,
            type: "fly"
        });

        mapPoint.setCoordinates(mapLocation);
        rad.setCoordinates(mapLocation);
    }, [mapLocation]);

    useEffect(() => {
        if (rad === null || rad === undefined) {
            return;
        }

        rad.addProperty("radius", Number(selLocation.radiusFence));
    }, [selLocation.radiusFence]);

    useEffect(() => {
        if (!isSearchMap) {
            return;
        }

        const reg = /^[\d\.]+,\s*[\d\.]+\s*$/g;

        if (reg.exec(searchMap)) {
            const coorSrch = searchMap.split(",");

            if (coorSrch.length === 0) {
                setIsSearchMap(false);
                return;
            }

            const coorLat = coorSrch[0];
            const coorLong = coorSrch[1];

            onSearchMapClick([ Number(coorLong), Number(coorLat) ]);

            setIsSearchMap(false);
            
            return;
        }

        (
            async () => { await onSearchMap() }
        )();
    }, [isSearchMap]);

    useEffect(() => {
        if (!pageSel.msgResult) {
            return;
        }

        (
            async () => {
                dispatch(setLoadingNow());

                try {
                    if (pageSel.msgId === "DEL_LOCATION") {
                        await DeleteLocation(selLocation.id);

                        await refreshList();

                        onSelection(defaultLocation);

                        dispatch(hideMsg());
                    }
                } catch (ex) {
                    dispatch(showMsg({
                        title: "Error",
                        content: `Unable to proceed. ${ ex.message }`
                    }));
                } finally {
                    dispatch(setLoadingComplete());
                }
            }
        )();
    }, [pageSel.msgResult]);

    return (
        <>
            <strong className="title">Locations</strong>
            <div className="panel-horizontal">
                <div className="panel-inner panel-inner-start">
                    <Button src="/img/refresh.svg" onClick={async () => { await refreshList() }}/>
                    <Button label="New" src="/img/add.svg" onClick={() => { onSelection(defaultLocation) }}/>
                    <Button label="Delete" src="/img/remove.svg" onClick={ onDelete }/>
                </div>
                <div className="panel-inner panel-inner-end"></div>
            </div>
            <div className="side-panel location-side-panel">
                <div className="form-section">
                    <strong>Details</strong>
                </div>
                <div className="search-map">
                    <input 
                        className="listing-search-box listing-search-map" 
                        type="text" 
                        placeholder="search" 
                        value={ searchMap } 
                        onChange={(e) => { setSearchMap(e.target.value) }}
                    />
                    <Button src="/img/search.svg" onClick={() => { setIsSearchMap(true) }}/>
                </div>
                <div id="map" className="map"></div>
                <form onSubmit={ onSave }>
                    <div className="radius-slider">
                        <Slider 
                            label="Fence Coverage" 
                            unit={ !isKM ? "Meters" : "KM" }
                            min={ !isKM ? 10 : 1 }
                            max={ !isKM ? 999 : 10}
                            step={ !isKM ? 1 : 0.1 }
                            initValue={ !isKM ? selLocation.radiusFence : selLocation.radiusFence / 1000 }
                            onChange={(e) => { 
                                const radKm = !isKM ? e : e * 1000;
                                onFieldChange(radKm, "RADIUS_FENCE");
                            }}
                        />
                        <div className="radius-toggle">
                            <CheckBox 
                                label="in KM" 
                                checked={ isKM } 
                                onChange={() => { 
                                    let radKm;

                                    if (isKM === false) {
                                        // change to KM
                                        radKm = selLocation.radiusFence <= 999 ? 1000 : selLocation.radiusFence;
                                    } else {
                                        // change to M
                                        radKm = selLocation.radiusFence >= 999 ? 999 : selLocation.radiusFence;
                                    }

                                    onFieldChange(radKm, "RADIUS_FENCE");

                                    setIsKM(!isKM);
                                }}
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <TextInput label="Code" required value={ selLocation.locationCode } onChange={(e) => { onFieldChange(e, "LOCATION_CODE") }}/>
                        <TextInput label="Description" value={ selLocation.description } onChange={(e) => { onFieldChange(e, "DESCRIPTION") }}/>
                    </div>
                    <div className="form-row">
                        <CheckBox
                            label="Use Location Description as Address"
                            checked={selLocation.useDescAddress}
                            onChange={() => { onFieldChange(!selLocation.useDescAddress, "USE_DESC_ADDRESS") }}
                        />
                    </div>
                    <div className="form-row">
                        <CheckBox 
                            label="QR Code Require" 
                            checked={ selLocation.mandatoryQRCode } 
                            onChange={() => { onFieldChange(!selLocation.mandatoryQRCode, "MANDATORY_QR_CODE") }}
                        />
                    </div>
                    <div className="form-row form-row-border-top">
                        <div></div>
                        <div></div>
                        <div className="form-row" style={{ padding: 0 }}>
                            <SaveButton /> 
                            <CancelButton onClick={() => { setIsCancel(true) }}/>
                        </div>
                    </div>
                </form>
            </div>
            <div className="listing">
                <input className="listing-search-box" type="text" placeholder="search" onChange={(e) => { setSearch(e.target.value) }}/>
                <div>
                    { lst }
                </div>
            </div>
            <div className={lstSearchMap.length > 0 ? "dialog map-dialog map-dialog-show" : "dialog map-dialog" }>
                <div className="dialog-header">
                    <span>Search Map Results</span>
                    <button onClick={ onCloseSearchMap }>
                        <img src="/img/close_black.svg" />
                    </button>
                </div>
                <div className="dialog-body">{
                    lstSearchMap
                }</div>
            </div>
            <Help sectionName="Locations">
                <h3>Geo-Fencing Location</h3>
                <p>Geo-fencing location is a feature used by iFlexi Clock to limit location allowed to perform clocking.</p>
                <h3>Define New Location</h3>
                <ul>
                    <li>To define new location, click on <strong>"New"</strong> button.</li>
                    <li>Click on location on the map.</li>
                    <li>To ease your location searching, enter <strong>street name</strong> or <strong>point of interest</strong> 
                    in the search box above the map and click on <img  src="/img/search.svg" /> button.</li>
                    <li>Click on result display then hold-click to move the map around.</li>
                    <li>Single-click on the map to select location for geo-fencing.</li>
                    <li>To increase the size of clocking coverage, drag <strong>"Fence Coverage"</strong> slider.</li>
                    <li>Enter particulars.</li>
                    <li>Click <strong>"Save"</strong> to confirm.</li>
                </ul>
                <h3>Use location's description as clock data address</h3>
                <ul>
                    <li>By enabling <strong>"Use Location Description as Address"</strong>, iFlexi Clock mobile app. will use defined description as location address if user is within location radius.</li>
                    <li>This option will affect all users, regardless of geo-fencing setting.</li>
                </ul>
                <h3>Delete Location</h3>
                <ul>
                    <li>To delete simply click on desire location from the location listing.</li>
                    <li>Click <strong>"Delete"</strong> button.</li>
                </ul>
            </Help>
        </>
    );
}

const defaultLocation = {
    id: "-1",
    locationCode: "",
    description: "",
    latitude: "1.549652396775663",
    longitude: "103.78610070962547",
    mandatoryQRCode: false,
    radiusFence: Number(10),
    useDescAddress: false
};