import { 
  useState, 
  useEffect 
} from "react";

import { FormatCompDate } from "../miscfunction/DateTimeFormat";

import "./Input.css";

export function ActionButton(props) {
    return (
        <button className="action-button" { ...props }>
            <img src={ props.src } alt="action-button" />
        </button>
    );
}

export function Button(props) {
    return (
        <button 
          className="button"
          onClick={ props.onClick }
        >
          { 
            props.src && (
              <img src={ props.src } alt={ props.alt } />
            ) 
          }            
          { 
            props.label && (
              <span 
                className={ 
                  props.src !== undefined ? 
                  "button-label button-label-margin" : 
                  "button-label" 
                  }
                >
                { props.label }
              </span>
            )
          }
        </button>
    );
}

export function SaveButton(props) {
    return (
        <Button
            submit
            label="Save"
            src="/img/save_white.svg"
            { ...props }
        />
    );
}

export function CancelButton(props) {
    return (
        <Button
            label="Cancel"
            src="/img/cancel_white.svg"
            { ...props }
        />
    );
}

export function TextInput(props) {
    const [val, setVal] = useState("");
  
    return(
      <div className={ props.horizontal ? "input-container horizontal-input" : "input-container" }>
        <label>{ props.label }</label>
        <input 
          className={ props.readonly ? "input-text input-text-readonly" : "input-text" } 
          type={ props.type } 
          placeholder={ props.placeholder ? props.placeholder : "" } 
          autoFocus={ props.autofocus } 
          onChange={(e) => {
            const value = e.target.value;
    
            if (props.value === undefined) {
              setVal(value);
            }
    
            if (props.onChange) {
              props.onChange(value);
            }
          }}
          readOnly={ props.readonly } 
          required={ props.required }
          value={ props.value ? props.value : val } 
          maxLength={ props.maxlength }
        />
        {
          props.required && props.hideRequireLabel === undefined && (
            <span className="required-label">*required</span>
          )
        }
      </div>
    );
  }

  export function ComboBox(props) {
    const onChange = (e) => {
      const { value } = e.target;
  
      if (props.readonly)
        return;
  
      if (props.onChange) {
        props.onChange(value);
      }
    };
  
    return(
      <div className={ props.horizontal ? "combobox combobox-horizontal" : "combobox" }>
        <label>{ props.label }</label>
        <select 
          onChange={ onChange } 
          required={ props.required } 
          value={ props.value ? props.value : undefined }
          { ...props }
        >
        { !props.required && (<option value=""></option>) }
        { props.opts }
        </select>
      </div>
    );
  }

  
export function CheckBox(props) {
    // const [isChecked, setChecked] = useState(props.checked ? props.checked : false);
  
    const onChange = () => {
      // const chkState = !isChecked;
  
      // if (props.readonly)
      //   return;
  
      // setChecked(chkState);
  
      // if (props.onChange) {
      //   props.onChange(chkState);
      // }

      props.onChange();
    };

    // useEffect(() => {
    //   setChecked(props.checked)
    // }, [props.checked]);

    return(
      <div className="checkbox">
        <div onClick={ onChange }>
          <img style={{ display: props.checked ? "block" : "none" }} src="/img/check_box.svg"/>
          <img style={{ display: !props.checked ? "block" : "none" }} src="/img/check_box_outline.svg"/>
        </div>
        <label>{ props.label }</label>
      </div>
    );
  }

  export function DateInput(props) {
    const [dateVal, setDateVal] = useState(new Date());
    const inputClass = props.readonly ? "input input-readonly" : "input";
  
    useEffect(() => {
      if (props.value) {
        setDateVal(FormatCompDate(new Date(props.value)));
      }
    }, []);
  
    return(
      <div className="dateinput">
        <label>{ props.label }</label>
        <input 
          className={ props.readonly ? "input-text input-text-readonly" : "input-text" } 
          type="date" 
          autoFocus={ props.autofocus } 
          onChange={(e) => {
            const value = e.target.value;
    
            setDateVal(value);
    
            if (props.onChange) {
              let result = new Date(value);
              props.onChange(result);
            }
          }}
          readOnly={ props.readonly } required={ props.required }
          value={ dateVal }
        />
        {
          props.required && (
            <span className="required-label">*required</span>
          )
        }
      </div>
    );
  }

  export function CheckBoxCollection(props) {
    const [ds, setDs] = useState([]);
    const [lst, setLst] = useState([]);

    const [selId, setSelId] = useState([]);

    const [search, setSearch] = useState("");

    const itemCheckChange = (id) => {
      const sel = selId.map((row) => row);

      const i = sel.findIndex((row) => row === id);

      if (i > -1) {
        sel.splice(i, 1);
      } else {
        sel.push(id);
      }

      setSelId(sel);

      props.onSelectionChange(sel);
    };

    useEffect(() => {
      setDs(props.data);

      if (props.checked !== undefined) {
        setSelId(props.checked);
      }
    }, [props.data, props.checked]);

    useEffect(() => {
      const lstChk = [];

      let data = props.data.map((row) => row);

      if (search !== "") {
        data = props.data.filter((row) => {
          const lbl = row.label.toLowerCase();
          return lbl.indexOf(search.toLowerCase()) > -1;
        });
      }

      const sel = selId.map((row) => row);

      for (let i = 0; i < data.length; i++) {
        const { id, label } = data[i];

        const isChecked = sel.findIndex((row) => row === id) > -1;

        lstChk.push((
          <div key={ id } className="checkbox-collection-item">
            <CheckBox label={ label } checked={ isChecked } onChange={() => { itemCheckChange(id) }} />
          </div>
        ));
      }

      setLst(lstChk);
    }, [ds, selId, search]);

    return (
      <div className="checkbox-collection" { ...props }>
        {
          props.search !== undefined && (
            <input className="searchbox-collection" placeholder="search" onChange={(e) => { setSearch(e.target.value) }} />
          )
        }
        { lst }
      </div>
    );
  }

  export function Slider(props) {
    const [val, setVal] = useState(0);
  
    const onChange = (e) => {
      setVal(e.target.value);
  
      if (props.onChange !== undefined) {
        props.onChange(e.target.value);
      }
    };
  
    useEffect(() => {
      if (props.initValue === undefined) {
        return;
      }
  
      setVal(props.initValue);
    }, [props.initValue]);
  
    return (
      <>
        <div className="slider-container">
          <div className="slider-label">
            <label>{ props.label }</label>
            <label>{ val }&#32;{ props.unit }</label>
          </div>
          <input
            type="range"
            min={ props.min }
            max={ props.max }
            step={ props.step }
            onChange={ onChange }
            value={ val } />
        </div>
      </>
    );
  }

  export function TextArea(props) {
    const [val, setVal] = useState("");

    return (
      <div className={ props.horizontal ? "input-container horizontal-input" : "input-container" }>
        <label>{ props.label }</label>
        <textarea
          className={ props.readonly ? "input-text input-text-readonly" : "input-text" } 
          onChange={(e) => {
            const value = e.target.value;
    
            if (props.value === undefined) {
              setVal(value);
            }
    
            if (props.onChange) {
              props.onChange(value);
            }
          }}
          readOnly={ props.readonly } 
          required={ props.required }
          value={ props.value ? props.value : val } 
          maxLength={ props.maxlength }
        ></textarea>
        {
          props.required && (
            <span className="required-label">*required</span>
          )
        }
      </div>
    );
  }

  export function DateTimeInput(props) {
    const [dateVal, setDateVal] = useState(new Date());
    const [timeVal, setTimeVal] = useState(new Date());
    const inputClass = props.readonly ? "input input-readonly" : "input";
  
    useEffect(() => {
      if (props.value) {
        setDateVal(FormatCompDate(new Date(props.value)));
        setTimeVal("00:00");
      }
    }, []);

    useEffect(() => {
      const dtVal = new Date(props.value);
      
      dtVal.setHours(0);
      dtVal.setMinutes(0);
      dtVal.setSeconds(0);

      setDateVal(dtVal);

      const tVal = new Date(props.value);

      setTimeVal(tVal);

      console.log("Trigger");
    }, [props.value]);
  
    return(
      <div className="datetimeinput-container">
        <label>{ props.label }</label>
        <div className="input-container">
          <input 
            className={ inputClass } 
            type="date" 
            autoFocus={ props.autofocus } 
            onChange={(e) => {
              const value = e.target.value != "" ? e.target.value : FormatCompDate(new Date());
    
              setDateVal(value);
    
              if (props.onChange) {
                let result = new Date(value);
    
                const tmVal = timeVal != "" ? timeVal : "00:00";
    
                const tmHour = tmVal.toString().split(":")[0];
                const tmMinute = tmVal.toString().split(":")[1];
    
                result.setHours(tmHour);
                result.setMinutes(tmMinute);
                result.setSeconds(0);
    
                props.onChange(result);
              }
            }}
            readOnly={ props.readonly } required={ props.required }
            value={ dateVal } 
          />
          <input 
            className={ inputClass } 
            type="time" 
            value={ timeVal } 
            onChange={(e) => {
              const value = e.target.value != "" ? e.target.value : "00:00";
    
              setTimeVal(value);
    
              if (props.onChange) {
                let result = new Date(dateVal);
    
                const tmVal = value != "" ? value : "00:00";
    
                const tmHour = tmVal.toString().split(":")[0];
                const tmMinute = tmVal.toString().split(":")[1];
    
                result.setHours(tmHour);
                result.setMinutes(tmMinute);
                result.setSeconds(0);
    
                props.onChange(result);
              }
            }} 
            readOnly={ props.readonly } 
            required={ props.required } 
          />
        </div>
        {
          props.required && (
            <span className="required-label">*required</span>
          )
        }
      </div>
    );
  }